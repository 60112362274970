import React, { Component } from 'react';

class ContactForm extends Component {
    render() {
        return (
            <div className="form-container">
                <h2>Stuur ons een bericht</h2>
                <form className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Naam:</label>
                        <input type="text" id="name" name="name" className="input-field" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">E-mail:</label>
                        <input type="email" id="email" name="email" className="input-field" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Bericht:</label>
                        <textarea id="message" name="message" className="input-field" required />
                    </div>
                    <button type="submit" className="submit-btn">Verstuur</button>
                </form>
            </div>
        );
    }
}

export default ContactForm;
