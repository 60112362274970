import React, { Component } from 'react';

class Hero extends Component {
    render() {
        return (
            <div style={{ backgroundColor: '#1575a0', padding: '50px', textAlign: 'center' }}>
                <h1 style={{ color: 'white' }}>Welkom bij Burhan's Aannemersbedrijf</h1>
                <p style={{ color: 'white' }}>We bouwen met passie en precisie!</p>
            </div>
        );
    }
}

export default Hero;
