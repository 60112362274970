import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css';


const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img
                    src="/images/BurhansCroppedWhite.jpg"
                    alt="Burhan's Aannemersbedrijf"
                    className="logo-img"
                />
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <span className={`bar ${menuOpen ? 'open' : ''}`}></span>
                <span className={`bar ${menuOpen ? 'open' : ''}`}></span>
                <span className={`bar ${menuOpen ? 'open' : ''}`}></span>
            </div>
            <ul className={`navbar-links ${menuOpen ? 'open' : ''}`}>
                <li>
                    <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/over-ons" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                        Over Ons
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/diensten" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                        Diensten
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/projecten" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                        Projecten
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')} onClick={toggleMenu}>
                        Contact
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
