// Home.js
import React, { Component } from 'react';
import Hero from '../components/Hero';
import { Link } from 'react-router-dom';
import '../styles/Home.css'; // Import the CSS file here

class Home extends Component {
    render() {
        return (
            <div>
                {/* Hero Section */}
                <Hero />

                {/* Diensten Section */}
                <section className="section">
                    <h2>Diensten</h2>
                    <div className="card-container">
                        <div className="card">
                            <h3>Nieuwbouw</h3>
                            <p>Creëer uw droomhuis van de grond af aan.</p>
                        </div>
                        <div className="card">
                            <h3>Renovatie</h3>
                            <p>Laat uw bestaande ruimtes weer schitteren.</p>
                        </div>
                        <div className="card">
                            <h3>Fundering</h3>
                            <p>Betrouwbare basis voor elk project.</p>
                        </div>
                    </div>
                    <Link to="/diensten" className="link">
                        Bekijk al onze diensten →
                    </Link>
                </section>

                {/* Projecten Section */}
                <section className="section">
                    <h2>Onze Projecten</h2>
                    <div className="image-gallery">
                        <img src="/images/project1.jpg" alt="Project 1" className="image" />
                        <img src="/images/project2.jpg" alt="Project 2" className="image" />
                        <img src="/images/project3.jpg" alt="Project 3" className="image" />
                    </div>
                    <Link to="/projecten" className="link">
                        Bekijk meer projecten →
                    </Link>
                </section>

                {/* Contact CTA */}
                <section className="section" style={{ backgroundColor: '#777777', color: 'white' }}>
                    <h2>Neem Contact Op</h2>
                    <p>
                        Klaar om uw volgende project te starten? Wij zijn hier om uw ideeën werkelijkheid te maken.
                    </p>
                    <Link to="/contact" className="link" style={{ color: '#1575a0' }}>
                        Contacteer ons →
                    </Link>
                </section>
            </div>
        );
    }
}

export default Home;
