import React, { Component } from 'react';
import '../styles/Footer.css';  // Zorg ervoor dat je de CSS importeert
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Gebruik FontAwesome iconen
import '@fortawesome/fontawesome-free/css/all.min.css';


class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-contact">
                        <h3>Neem contact op</h3>
                        <div className="contact-info">
                            <p><FaPhoneAlt/> <a href="tel:+31201234567">+31 20 123 4567</a></p>
                            <p><FaEnvelope/> <a href="mailto:info@burhanbedrijf.nl">info@burhanbedrijf.nl</a></p>
                        </div>
                    </div>

                    <div className="footer-social">
                        <h3>Volg ons op</h3>
                        <div className="social-icons">
                            <a href="https://www.tiktok.com/@burhan_aannemersbedrijf" target="_blank"
                               rel="noopener noreferrer">
                                <i className="fab fa-tiktok"></i>
                            </a>
                            <a href="https://www.instagram.com/burhan_aannemersbedrijf/" target="_blank"
                               rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://twitter.com/burhan_aannemersbedrijf" target="_blank"
                               rel="noopener noreferrer">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </div>
                    </div>


                    <div className="footer-info">
                        <p>&copy; 2024 Burhan's Aannemersbedrijf. Alle rechten voorbehouden.</p>
                        <ul>
                            <li><a href="/privacy">Privacybeleid</a></li>
                            <li><a href="/algemene-voorwaarden">Algemene Voorwaarden</a></li>
                        </ul>
                    </div>
                </div>

                <div className="footer-background">
                    <div className="footer-background-icon">
                        <img src="/images/BurhansCroppedNoBackground.png" alt="Burhan Logo"
                             className="footer-logo"/>
                    </div>
                </div>

            </footer>
        );
    }
}

export default Footer;
