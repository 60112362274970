import React, { Component } from 'react';
import '../styles/About.css';

class About extends Component {
    render() {
        return (
            <div className="about-container">
                <section className="about-hero">
                    <h2 className="about-title">Over Ons</h2>
                    <p className="about-subtitle">
                        Wij combineren traditioneel vakmanschap met een moderne visie om duurzame en esthetische projecten te leveren.
                    </p>
                </section>
                <section className="about-content">
                    <div className="about-card">
                        <h3>Onze Missie</h3>
                        <p>
                            Het bouwen van hoogwaardige constructies met oog voor detail, duurzaamheid, en klanttevredenheid.
                        </p>
                    </div>
                    <div className="about-card">
                        <h3>Onze Visie</h3>
                        <p>
                            Innovatie in bouwtechnieken combineren met traditionele waarden om unieke en tijdloze structuren te creëren.
                        </p>
                    </div>
                    <div className="about-card">
                        <h3>Ons Team</h3>
                        <p>
                            Een hechte familie van vakmensen met jarenlange ervaring in het leveren van kwaliteit en betrouwbaarheid.
                        </p>
                    </div>
                </section>
            </div>
        );
    }
}

export default About;
