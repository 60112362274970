import React from 'react';
import ContactForm from "../components/ContactForm";
import '../styles/Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <section className="intro-section">
                <h1>Neem Contact met Ons Op</h1>
                <p>We horen graag van je! Laat ons weten hoe we je kunnen helpen.</p>
            </section>
            <div className="contact-form-wrapper">
                <ContactForm />
            </div>
        </div>
    );
};

export default Contact;
