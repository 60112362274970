import React, { useState } from "react";
import "../styles/Services.css";

const servicesData = [
    { title: "Nieuwbouw", icon: "fas fa-home", description: "Wij bouwen jouw droomhuis met precisie en vakmanschap. Van fundering tot dakbedekking zorgen we ervoor dat je woning voldoet aan de hoogste kwaliteitsnormen." },
    { title: "Aan/opbouw", icon: "fas fa-expand-arrows-alt", description: "Breid je woning uit met onze betrouwbare expertise. Of het nu gaat om een extra verdieping of een uitbreiding van je woonruimte, wij zorgen voor een stevige basis." },
    { title: "Renovatie", icon: "fas fa-tools", description: "We brengen je woning terug naar zijn volle glorie. Van het vernieuwen van keukens en badkamers tot het herstellen van oude structuren, wij zorgen voor een duurzame en moderne uitstraling." },
    { title: "Kozijnen leveren en monteren", icon: "fas fa-window-maximize", description: "Hoge kwaliteit kozijnen voor je woning, die niet alleen esthetisch mooi zijn maar ook zorgen voor betere isolatie en veiligheid." },
    { title: "Stuc en schilderswerkzaamheden", icon: "fas fa-paint-roller", description: "Prachtige afwerkingen voor je binnen- en buitenschilderwerk. We zorgen voor een strakke afwerking die niet alleen mooi is maar ook duurzaam." },
    { title: "Badkamer/sanitair", icon: "fas fa-bath", description: "Creëer je ideale badkamer met onze professionele installaties. Van het installeren van nieuwe sanitair tot het opnieuw inrichten van de ruimte." },
    { title: "Vloerverwarming aanleggen", icon: "fas fa-thermometer-half", description: "Installeer vloerverwarming voor ultiem comfort in je woning. Het is energiezuinig en zorgt voor een gelijkmatige temperatuurverdeling in je huis." },
    { title: "Trappen aanleggen/renoveren", icon: "fas fa-arrow-up", description: "Wij leveren en installeren trappen van topkwaliteit, met diverse stijlen en materialen om de perfecte trap voor jouw huis te vinden." },
    { title: "(Monumentale) Pand onderhoud", icon: "fas fa-building", description: "Specialisten in het onderhouden van monumentale panden. Wij zorgen ervoor dat historische gebouwen veilig en goed onderhouden blijven, met respect voor de oorspronkelijke kenmerken." },
    { title: "Cv en installatietechnieken", icon: "fas fa-fire", description: "Betrouwbare cv-installaties voor jouw woning, met energiezuinige oplossingen en een langere levensduur van je verwarming." },
    { title: "Funderingswerkzaamheden", icon: "fas fa-cogs", description: "Sterke en betrouwbare funderingen voor je project, essentieel voor de stabiliteit van elk gebouw." }
];

const Services = () => {
    const [activeService, setActiveService] = useState(null);

    const toggleDropdown = (index) => {
        if (activeService === index) {
            setActiveService(null); // Sluit de dropdown als deze al open is
        } else {
            setActiveService(index); // Open de dropdown voor het geselecteerde item
        }
    };

    return (
        <section className="services-section">
            <div className="services-header">
                <h2>Onze Diensten</h2>
                <p>Van renovatie tot nieuwbouw, wij realiseren jouw project met precisie en vakmanschap.</p>
            </div>
            <div className="services-list">
                {servicesData.map((service, index) => (
                    <div key={index} className="service-item">
                        <div className="service-header" onClick={() => toggleDropdown(index)}>
                            <div className="service-icon">
                                <i className={service.icon}></i>
                            </div>
                            <h3>{service.title}</h3>
                            <span className="arrow">{activeService === index ? "▲" : "▼"}</span>
                        </div>
                        {activeService === index && (
                            <div className="service-description">
                                <p>{service.description}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Services;
